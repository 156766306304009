.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#tsparticles,
#tsparticles2 {
    position: absolute;
    width: 100%;
    height: 100%;
}

#tsparticles {
    background-image: url('/wp-city.jpg');
}

.banner {
    height: 100vh;
    width: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.banner:after {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /*background:transparent linear-gradient(to right bottom, #3f51b5, #ff4081);
  background:transparent linear-gradient(to right bottom, #8e0e00, #1f1c18);
  background:transparent linear-gradient(to right bottom, #005c97, #363795);*/
    background: transparent linear-gradient(to right bottom, #4b79a1, #283e51);
    z-index: -1;
    opacity: .6;
}